<template>
  <div class="productBox">
    <div class="wrap">
      <div class="productNav clearfix">
        <span class="productNavList">
          <span
            v-for="route in routerList"
            :key="route.label"
            class="listInfo-box"
          >
            <span class="listInfo">
              <span class="listInfo-iconfont">
                <i class="listInfoIcon iconfont icon-youbianjiantou">
                </i>
              </span>

              <span class="listInfoSize">
                <span @click="tolink(route.path)">
                  {{$t(route.label)}}
                </span>
              </span></span>
          </span>
        </span>
        <span
          class="return"
          @click="onClickBack(back.path)"
          v-for="back in tobackList"
          :key="back.label"
        >
          <span>{{$t(back.label)}}</span>
          <img
            :src="back.image"
            alt=""
          ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routerList: {
      required: true,
    },
    tobackList: {
      required: true,
    },
  },
  methods: {
    tolink(link) {
      this.$router.push({
        path: link,
      });
    },
    onClickBack(link) {
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.productBox {
  .wrap {
    position: relative;
    .productNav {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      position: absolute;
      top: 0px;
      left: 0;
      height: 60px;
      line-height: 60px;

      .productNavList {
        .listInfo-box:nth-child(1) {
          margin-left: 30px;
          .listInfo {

            .listInfo-iconfont {
              .iconfont {
                display: none;
              }
            }
          }
        }

        .listInfoIcon {
          margin: 0 10px;
        }

        .listInfo-box:last-child {
          .listInfoSize {
            color: #666;
            cursor: pointer;
          }
        }
      }

      .return {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 28px;

        &:hover{
          cursor: pointer;
        }
        img {
          position: relative;
          top: -23px;
          right: -5px;
        }
      }
    }
  }
}
</style>
