<template>
  <div class="product">
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.product {
  background: url(../assets/image/product/banner.png) no-repeat center;
  height: 700px;
  width: 100%;
  position: relative;
  top: 0px;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .product {
    width: 1200px;
  }
}
</style>
